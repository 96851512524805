import { api } from '../../api'
import {
  DeleteTomPdVersionRequest,
  DownloadTomPdVersionRequest,
  DownloadTomPdVersionResponse,
  GetTomPdVersionsRequest,
  GetTomPdVersionsResponse,
  TomPdVersionBaseResponse,
  UploadTomPdVersionRequest
} from './tomVersionApi.types'
import { GetTomPdByIdRequest, GetTomsPdRequest, tomPdApi } from '../tom'
import { setCurrentDocument } from '../../../store/slices/documentsPages/tom'

export const tomPdVersionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomPdVersions: build.query<GetTomPdVersionsResponse, GetTomPdVersionsRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom-pd/${id}/pdf/list`,
        params,
        method: 'GET',
      }),
      providesTags: ['TomPd', 'TomPdDoc'],
    }),
    createTomPdVersion: build.mutation<TomPdVersionBaseResponse, UploadTomPdVersionRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/tom-pd/${id}/pdf/create`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedVersionData } = await queryFulfilled
          const uploadedVersion = uploadedVersionData.data

          // getTomsPd
          const getTomsPdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomsPd'))
          const getTomsPdLastKey = getTomsPdKeys[getTomsPdKeys?.length - 1]

          dispatch(
            tomPdApi.util.updateQueryData(
              'getTomsPd',
              state.api.queries[getTomsPdLastKey]?.originalArgs as GetTomsPdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex(tom => tom.id === uploadedVersion.tomId)
                draft.data[changedTomIndex].version = uploadedVersion.version
              }
            )
          )

          // getTomPdById
          const getTomPdByIdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomPdById'))
          const getTomPdByIdLastKey = getTomPdByIdKeys[getTomPdByIdKeys?.length - 1]

          dispatch(
            tomPdApi.util.updateQueryData(
              'getTomPdById',
              state.api.queries[getTomPdByIdLastKey]?.originalArgs as GetTomPdByIdRequest,
              (draft) => {
                draft.data.version = uploadedVersion.version
              }
            )
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedVersion }))

        } catch { }
      },
    }),
    uploadTomPdVersion: build.mutation<TomPdVersionBaseResponse, UploadTomPdVersionRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/tom-pd/${id}/pdf/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedVersionData } = await queryFulfilled
          const uploadedVersion = uploadedVersionData.data

          // getTomsPd
          const getTomsPdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomsPd'))
          const getTomsPdLastKey = getTomsPdKeys[getTomsPdKeys?.length - 1]

          dispatch(
            tomPdApi.util.updateQueryData(
              'getTomsPd',
              state.api.queries[getTomsPdLastKey]?.originalArgs as GetTomsPdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex(tom => tom.id === uploadedVersion.tomId)
                draft.data[changedTomIndex].version = uploadedVersion.version
              }
            )
          )

          // getTomPdById
          const getTomPdByIdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomPdById'))
          const getTomPdByIdLastKey = getTomPdByIdKeys[getTomPdByIdKeys?.length - 1]

          dispatch(
            tomPdApi.util.updateQueryData(
              'getTomPdById',
              state.api.queries[getTomPdByIdLastKey]?.originalArgs as GetTomPdByIdRequest,
              (draft) => {
                draft.data.version = uploadedVersion.version
              }
            )
          )

          // getTomPdVersions
          const getTomPdVersionsKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomPdVersions'))
          const getTomPdVersionsLastKey = getTomPdVersionsKeys[getTomPdVersionsKeys?.length - 1]

          dispatch(
            tomPdVersionApi.util.updateQueryData(
              'getTomPdVersions',
              state.api.queries[getTomPdVersionsLastKey]?.originalArgs as GetTomPdVersionsRequest,
              (draft) => {
                draft.data.unshift(uploadedVersion)
              }
            )
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedVersion }))

        } catch {
        }
      },
    }),
    deleteTomPdVersion: build.mutation<TomPdVersionBaseResponse, DeleteTomPdVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/tom-pd/${id}/pdf/delete`,
        params: { version },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomPd', 'TomPdDoc'],
    }),
    downloadTomPdVersion: build.mutation<DownloadTomPdVersionResponse, DownloadTomPdVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/tom-pd/${id}/pdf/download`,
        params: { version },
        method: 'GET',
      }),
    }),
    // getTomPdVersionLink: build.mutation<string, GetTomPdVersionLinkRequest>({
    //   query: ({ id, version }) => ({
    //     url: `/project/tom-pd/${id}/pdf/get-link`,
    //     params: { version },
    //     method: 'GET',
    //   }),
    // }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomPdVersionsQuery,
  useCreateTomPdVersionMutation,
  useUploadTomPdVersionMutation,
  useDeleteTomPdVersionMutation,
  useDownloadTomPdVersionMutation,
} = tomPdVersionApi
