import { useState } from 'react'
import { UseDownloadDocResponse } from './useDownloadDoc.types'
import {
  DownloadTomRdChangeResponse,
  DownloadTomRdVersionResponse,
  useDownloadTomRdChangeMutation,
  useDownloadTomRdVersionMutation
} from '../../../../../../api/rdPhase'
import {
  DownloadTomPdChangeResponse,
  DownloadTomPdVersionResponse,
  useDownloadTomPdChangeMutation,
  useDownloadTomPdVersionMutation
} from '../../../../../../api/pdPhase'
import {
  DownloadTomIiChangeResponse,
  DownloadTomIiVersionResponse,
  useDownloadTomIiChangeMutation,
  useDownloadTomIiVersionMutation
} from '../../../../../../api/iiPhase'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
// @ts-ignore:next-line
import { triggerBase64Download } from 'common-base64-downloader-react'
import { TomDocument } from '../../../../../../types/tom'
import { useTypedSelector } from '../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { isCmnTomSelector, tomSelector } from '../../../../../../store/slices/documentsPages/tom'
import {
  DownloadTomIrdChangeResponse,
  DownloadTomIrdVersionResponse,
  useDownloadTomIrdChangeMutation,
  useDownloadTomIrdVersionMutation
} from '../../../../../../api/irdPhase'
import {
  DownloadTomCmnChangeResponse,
  DownloadTomCmnVersionResponse,
  useDownloadTomCmnChangeMutation,
  useDownloadTomCmnVersionMutation
} from '../../../../../../api/cmnTom'
import { CMN_TOM_TITLE } from '../../../../types'

export const useDownloadDoc = (): UseDownloadDocResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)
  const tomTitle = isCmnTom ? CMN_TOM_TITLE : tom?.title

  const [savedPdf, setSavedPdf] = useState<TomDocument | null>(null)

  // Rd
  const [downloadTomRdVersion, downloadTomRdVersionResponse] = useDownloadTomRdVersionMutation()
  const [downloadTomRdChange, downloadTomRdChangeResponse] = useDownloadTomRdChangeMutation()

  useMutationHandlers(
    downloadTomRdVersionResponse,
    (data: DownloadTomRdVersionResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} версия-${savedPdf?.version}`)
      setSavedPdf(null)
    }
  )

  useMutationHandlers(
    downloadTomRdChangeResponse,
    (data: DownloadTomRdChangeResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} изменение-${savedPdf?.change}`)
      setSavedPdf(null)
    }
  )


  // Pd
  const [downloadTomPdVersion, downloadTomPdVersionResponse] = useDownloadTomPdVersionMutation()
  const [downloadTomPdChange, downloadTomPdChangeResponse] = useDownloadTomPdChangeMutation()

  useMutationHandlers(
    downloadTomPdVersionResponse,
    (data: DownloadTomPdVersionResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} версия-${savedPdf?.version}`)
      setSavedPdf(null)
    }
  )

  useMutationHandlers(
    downloadTomPdChangeResponse,
    (data: DownloadTomPdChangeResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} изменение-${savedPdf?.change}`)
      setSavedPdf(null)
    }
  )


  // Ii
  const [downloadTomIiVersion, downloadTomIiVersionResponse] = useDownloadTomIiVersionMutation()
  const [downloadTomIiChange, downloadTomIiChangeResponse] = useDownloadTomIiChangeMutation()

  useMutationHandlers(
    downloadTomIiVersionResponse,
    (data: DownloadTomIiVersionResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} версия-${savedPdf?.version}`)
      setSavedPdf(null)
    }
  )

  useMutationHandlers(
    downloadTomIiChangeResponse,
    (data: DownloadTomIiChangeResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} изменение-${savedPdf?.change}`)
      setSavedPdf(null)
    }
  )


  // Ird
  const [downloadTomIrdVersion, downloadTomIrdVersionResponse] = useDownloadTomIrdVersionMutation()
  const [downloadTomIrdChange, downloadTomIrdChangeResponse] = useDownloadTomIrdChangeMutation()

  useMutationHandlers(
    downloadTomIrdVersionResponse,
    (data: DownloadTomIrdVersionResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} версия-${savedPdf?.version}`)
      setSavedPdf(null)
    }
  )

  useMutationHandlers(
    downloadTomIrdChangeResponse,
    (data: DownloadTomIrdChangeResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} изменение-${savedPdf?.change}`)
      setSavedPdf(null)
    }
  )


  // Cmn
  const [downloadTomCmnVersion, downloadTomCmnVersionResponse] = useDownloadTomCmnVersionMutation()
  const [downloadTomCmnChange, downloadTomCmnChangeResponse] = useDownloadTomCmnChangeMutation()

  useMutationHandlers(
    downloadTomCmnVersionResponse,
    (data: DownloadTomCmnVersionResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} версия-${savedPdf?.version}`)
      setSavedPdf(null)
    }
  )

  useMutationHandlers(
    downloadTomCmnChangeResponse,
    (data: DownloadTomCmnChangeResponse) => {
      triggerBase64Download(`data:application/pdf;base64,${data.data}`, `${tomTitle} изменение-${savedPdf?.change}`)
      setSavedPdf(null)
    }
  )


  // Common
  const onDownloadVersionByPhase = (pdf: TomDocument) => {
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        downloadTomRdVersion({ id: pdf?.tomId, version: pdf.version })
        break
      case 'Проектная документация':
        downloadTomPdVersion({ id: pdf?.tomId, version: pdf.version })
        break
      case 'Инженерные изыскания':
        downloadTomIiVersion({ id: pdf?.tomId, version: pdf.version })
        break
      case 'Сбор исходных данных':
        downloadTomIrdVersion({ id: pdf?.tomId, version: pdf.version })
        break
    }
    setSavedPdf(pdf)
  }

  const downloadCmnVersion = (pdf: TomDocument) => {
    downloadTomCmnVersion({ id: pdf?.tomId, version: pdf.version })
  }

  const onDownloadVersion = isCmnTom
    ? downloadCmnVersion
    : onDownloadVersionByPhase

  const onDownloadChangeByPhase = (pdf: TomDocument) => {
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        downloadTomRdChange({ id: pdf.tomId, change: pdf.change })
        break
      case 'Проектная документация':
        downloadTomPdChange({ id: pdf.tomId, change: pdf.change })
        break
      case 'Инженерные изыскания':
        downloadTomIiChange({ id: pdf.tomId, change: pdf.change })
        break
      case 'Сбор исходных данных':
        downloadTomIrdChange({ id: pdf.tomId, change: pdf.change })
        break
    }
    setSavedPdf(pdf)
  }


  const downloadCmnChange = (pdf: TomDocument) => {
    downloadTomCmnChange({ id: pdf?.tomId, change: pdf.change })
  }

  const onDownloadChange = isCmnTom
    ? downloadCmnChange
    : onDownloadChangeByPhase

  return {
    onDownloadVersion,
    onDownloadChange,
  }
}
