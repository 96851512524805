import React, { FC, ReactElement, useCallback } from 'react'
import { DocNavBarItem } from '../DocNavBarItem'
import { DocHandlers, DocListProps } from './DocList.types'
import { DocSideBarItem } from '../DocSideBarItem'
import { useDownloadDoc } from './hooks/useDownloadDoc'
import { useDeleteDoc } from './hooks/useDeleteDoc'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { docsSelector, setCurrentDocument } from '../../../../store/slices/documentsPages/tom'
import { TomDocument } from '../../../../types/tom'

export const DocList: FC<DocListProps> = ({ itemVariant, listVariant }) => {
  const dispatch = useAppDispatch()

  const { tomVersions, tomChanges } = useTypedSelector(docsSelector)

  const { onDownloadVersion, onDownloadChange } = useDownloadDoc()

  const onPdfCardClick = useCallback((pdf: TomDocument) => {
    dispatch(setCurrentDocument({ currentDocument: pdf }))
  }, [])

  const { onDeleteVersionClick, onDeleteChangeClick, ConfirmDialog } = useDeleteDoc()

  const versionHandlers: DocHandlers = {
    onClick: onPdfCardClick,
    onDelete: onDeleteVersionClick,
    onDownload: onDownloadVersion,
  }

  const changeHandlers: DocHandlers = {
    onClick: onPdfCardClick,
    onDelete: onDeleteChangeClick,
    onDownload: onDownloadChange,
  }

  const archiveVersionList: ReactElement = (
    <>
      {tomVersions?.slice(1)?.map((version) => {
        switch (itemVariant) {
          case 'navbar':
            return <DocNavBarItem doc={version} {...versionHandlers} />
          case 'sidebar':
            return <DocSideBarItem doc={version} {...versionHandlers} />
        }
      })}
    </>
  )

  const changeList: ReactElement = (
    <>
      {tomChanges?.map((change) => {
        switch (itemVariant) {
          case 'navbar':
            return <DocNavBarItem doc={change.doc} {...changeHandlers} />
          case 'sidebar':
            return <DocSideBarItem doc={change.doc} {...changeHandlers} />
        }
      })}
    </>
  )

  const lastVersionItem: Record<typeof itemVariant, ReactElement> = {
    navbar: <DocNavBarItem doc={tomVersions[0]} {...versionHandlers} />,
    sidebar: <DocSideBarItem doc={tomVersions[0]} {...versionHandlers} />,
  }

  const Content: Record<typeof listVariant, ReactElement> = {
    archiveVersions: archiveVersionList,
    changes: changeList,
    lastVersion: lastVersionItem[itemVariant],
  }

  return (
    <>
      {Content[listVariant]}
      <ConfirmDialog />
    </>
  )
}
