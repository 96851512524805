import { Delete as DeleteIcon, Download as DownloadIcon, Edit as EditIcon } from '@mui/icons-material'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { setOpenedDrawer } from 'store/slices/documentsPages/drawerInfo'
import { currentDocumentSelector, setCurrentChange, tomSelector } from 'store/slices/documentsPages/tom'

import { TomColoredInfo } from '@pages/Docs'

import { DocVariant } from '../../../../layouts/DocumentsLayout'
import { setSelectedMenuView } from '../../../../store/slices/documentsPages/menu'
import { isExtraAccessArchitectorSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { profileSelector } from '../../../../store/slices/profile'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import { TomDocument } from '../../../../types/tom'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'
import { tomRoleAccess } from '../DocViewNavBarContentInfo/components/ChangeSign/ChangeSign.types'
import { DocNavBarItemAttachmentsButton, DocNavBarItemWrapper } from './DocNavBarItem.styles'
import { DocNavBarItemProps } from './DocNavBarItem.types'

export const DocNavBarItem: FC<DocNavBarItemProps> = ({ doc, onClick, onDownload, onDelete }) => {
  const dispatch = useAppDispatch()

  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled = tom?.features.annulment.annulled

  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const { role } = useTypedSelector(profileSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)

  const { version, change, preview, uploadedBy, createdAt, attachCount } = doc
  const docVariant: DocVariant = change ? 'change' : 'version'
  const updateChangeAccess: boolean = tomRoleAccess.includes(role)

  const title: Record<DocVariant, string> = {
    change: `Изменение ${change}`,
    version: `Версия ${version}`,
  }

  const onAttachmentsClick = () => {
    dispatch(setSelectedMenuView({ selectedMenuView: 'attachments' }))
  }

  const onDownloadClick = (e: React.MouseEvent<HTMLButtonElement>, doc: TomDocument) => {
    e.stopPropagation()
    onDownload(doc)
  }

  const onEditClick = (e: React.MouseEvent<HTMLButtonElement>, doc: TomDocument) => {
    e.stopPropagation()
    dispatch(setCurrentChange({ change }))
    dispatch(setOpenedDrawer({ openedDrawer: 'docChange' }))
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement>, doc: TomDocument) => {
    e.stopPropagation()
    onDelete(doc)
  }

  return (
    <DocNavBarItemWrapper onClick={() => onClick(doc)} isSelected={currentDocument.docId === doc.docId}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2.5}>
        <Box width={63} height={90}>
          <img src={preview} width={63} height={90} alt='Превью документа' />
        </Box>

        <Stack spacing={1.125} flex={1}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
            <Box>
              <Typography variant='body2' height={21} lineHeight='21px' color={theme.palette.primary.main}>
                {title[docVariant]}
              </Typography>
            </Box>

            <TomColoredInfo direction='row' alignItems='center' customColor='blue' smallPadding thin>
              <Typography variant='tooltip' style={{ marginTop: 2 }}>
                pdf
              </Typography>
            </TomColoredInfo>
          </Stack>

          <Stack spacing={0.5}>
            <Box>
              <Typography variant='body2' lineHeight='13px' fontSize={12}>
                {uploadedBy}
              </Typography>
            </Box>
            <Box>
              <Typography variant='body2' lineHeight='13px' fontSize={12}>
                {convertDateTime(createdAt, false, true)}
              </Typography>
            </Box>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2.5}>
            <DocNavBarItemAttachmentsButton onClick={onAttachmentsClick} variant='text' disableRipple>
              <InsertDriveFileOutlinedIcon fontSize='medium' color='secondary' />
              <Box>
                <Typography variant='body2' mt={0.125} lineHeight='15px'>
                  {attachCount}
                </Typography>
              </Box>
            </DocNavBarItemAttachmentsButton>

            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1.25}>
              <IconButton onClick={(e) => onDownloadClick(e, doc)} sx={{ p: 0 }} disableRipple>
                <DownloadIcon fontSize='medium' color='secondary' />
              </IconButton>
              {!isAnnulled && updateChangeAccess && docVariant === 'change' && (
                <IconButton onClick={(e) => onEditClick(e, doc)} sx={{ p: 0 }} disableRipple>
                  <EditIcon fontSize='medium' color='secondary' />
                </IconButton>
              )}
              {role === 'admin' || (docVariant === 'change' && (role === 'client' || isExtraAccessArchitector)) ? (
                <IconButton onClick={(e) => onDeleteClick(e, doc)} sx={{ p: 0 }} disableRipple>
                  <DeleteIcon fontSize='medium' color='secondary' />
                </IconButton>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DocNavBarItemWrapper>
  )
}
