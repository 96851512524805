import { useEffect, useState } from 'react'
import {
  CoordinatesExtension,
  CropExtension,
  FlyControllerExtension,
  GeneralModeExtension,
  MeasureExtension,
  OrbitControllerExtension,
  RenderEvents,
  VisibilityExtension
} from 'tangl-viewer'
import { metaManager, renderManager, sceneManager } from './TanglManagers'
import { TanglViewerProps } from './TanglViewer.types'
import { InfoPage } from '../InfoPage'
import "/node_modules/tangl-viewer/dist/style.css";
import { StyledTanglViewer, ViewerCube } from './TanglViewer.styles'
import { store, useAppDispatch, useTypedSelector } from 'store/store'
import { selectedFromTreeElementsSelector } from 'store/slices/tim/selectors/tim.selectors'
import { setSelectedFromModelElements, setSelectedFromTreeElements } from 'store/slices/tim/tim'
import { CustomVisibilityExtension } from './Extensions/CustomVisibilityExtension'

export function TanglViewer({ tokenData, modelId, onError, isLoadingComplete }: TanglViewerProps) {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const selectedElements = useTypedSelector(selectedFromTreeElementsSelector)

  useEffect(() => {
    if (!sceneManager || !modelId) return

    renderManager?.init("viewer", "viewer-cube", true)
    renderManager.extMan.addExtension(OrbitControllerExtension)
    renderManager.extMan.selectControllerExtension('orbit')
    renderManager.extMan.addExtension(GeneralModeExtension)
    renderManager.extMan.selectModeExtension("general")
    renderManager.extMan.addExtension(FlyControllerExtension)
    renderManager.extMan.addExtension(CropExtension)
    renderManager.extMan.addExtension(CustomVisibilityExtension, {
      dispatch: store.dispatch,
      store: store,
    })
    /* renderManager.extMan.addExtension(VisibilityExtension) */
    renderManager.extMan.addExtension(MeasureExtension)
    renderManager.extMan.addExtension(CoordinatesExtension)
    renderManager.addAutoEventListener(RenderEvents.Click, () => {
      const elNum = renderManager.hoveredElNum
      if (elNum && elNum !== -1) {
        dispatch(setSelectedFromModelElements([elNum]))
      } else if (elNum && elNum === -1) {
        dispatch(setSelectedFromModelElements([]))
      }
    })

    return () => {
      const viewerDiv = document.getElementById('tgv-canvas')
      if (viewerDiv) {
        viewerDiv.remove()
      }
      renderManager.destroy()
      sceneManager.clear()
      metaManager.destroy()
      dispatch(setSelectedFromModelElements([]))
      dispatch(setSelectedFromTreeElements([]))
      setIsLoading(true)
    }
  }, [modelId])

  useEffect(() => {
    if (modelId && tokenData) {
      sceneManager?.setToken(tokenData)
      metaManager.setToken(tokenData)
      sceneManager.load(modelId)
      metaManager.load(modelId.map((id) => ({ id: id, name: id })))

    }
  }, [modelId, tokenData])

  useEffect(() => {
    if (selectedElements.length > 0) {
      sceneManager.updateSelection(selectedElements)
      dispatch(setSelectedFromModelElements([]))
    }

  }, [selectedElements])

  sceneManager.onError(() => {
    onError()
  })

  sceneManager.onAllLoaded(() => {
    setIsLoading(false)
    isLoadingComplete()
    renderManager?.zoomCameraToSelection()
  })

  return (
    <>
      <StyledTanglViewer id='viewer'
        children={
          isLoading && <InfoPage variant='loading' />
        }
      />
      <ViewerCube id='viewer-cube' />
      {/* <div id="tgv-metatree"></div> */}
    </>
  )
}