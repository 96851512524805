import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import GridViewIcon from '@mui/icons-material/GridView'
import PrintIcon from '@mui/icons-material/Print'
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw'
import { Box, Divider, Stack } from '@mui/material'
import { LocalizationMap, PageChangeEvent, RotateDirection, ScrollMode, Viewer, Worker } from '@react-pdf-viewer/core'
import { ToolbarProps, ToolbarSlot, defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import FullScreenIcon from '../../../../assets/icons/FullScreenIcon'
import Progress from '../../../../components/Progress'
import Tooltip from '../../../../components/Tooltip'
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo'
import { selectedMenuViewSelector } from '../../../../store/slices/documentsPages/menu'
import { isExtraAccessArchitectorSelector } from '../../../../store/slices/documentsPages/projectInfo'
import {
  currentDocumentSelector,
  docsSelector,
  isCmnTomSelector,
  setDocumentPages,
  tomSelector,
} from '../../../../store/slices/documentsPages/tom'
import { profileSelector } from '../../../../store/slices/profile'
import { setRemarkFormData } from '../../../../store/slices/remarks/remarks'
import { RemarkFormData } from '../../../../store/slices/remarks/remarks.types'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import { CMN_TOM_TITLE } from '../../types'
import { useDeleteDoc } from '../DocList/hooks/useDeleteDoc'
import { DocPageInfo, PdfViewerProps } from './PdfViewer.types'
import { MenuIconButton, PageNavigationWrapper, RemarkButton, StyledPdfViewer, TitleName, ZoomWrapper } from './styles'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const PdfViewer: React.FC<PdfViewerProps> = ({ remarkAcces }) => {
  const dispatch = useAppDispatch()
  const { role } = useTypedSelector(profileSelector)
  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled: boolean = !!tom?.features.annulment.annulled
  const { tomVersions } = useTypedSelector(docsSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)

  const [switchMode, setSwitchMode] = useState<ScrollMode>(ScrollMode.Vertical)
  const [docPageInfo, setDocPageInfo] = useState<DocPageInfo>({ currentPage: 1, pages: null })

  const isLatestVersion = useMemo(() => {
    if (currentDocument.version === null) return true
    if (tomVersions.length > 0) {
      return currentDocument.version === tomVersions[0].version
    }
  }, [currentDocument.version, tomVersions])

  const onSwitchModeClick = () => {
    setSwitchMode(switchMode === ScrollMode.Vertical ? ScrollMode.Wrapped : ScrollMode.Vertical)
  }

  useEffect(() => {
    switchScrollMode(switchMode)
  }, [switchMode])

  const { onDeleteVersionClick, onDeleteChangeClick, ConfirmDialog } = useDeleteDoc()

  const renderToolbar = useCallback(
    (Toolbar: (props: ToolbarProps) => React.ReactElement) => {
      return (
        <Toolbar>
          {(slots: ToolbarSlot) => {
            const { CurrentPageInput, NumberOfPages, ZoomIn, ZoomOut, Zoom, EnterFullScreen, Rotate, Download, Print } =
              slots
            return (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                flex={1}
                px={2.5}
                overflow='hidden'
              >
                <Stack direction='row' spacing={1.5} flex={1}>
                  <TitleName variant='h1'>{isCmnTom ? CMN_TOM_TITLE : tom?.title}.</TitleName>
                  <Box minWidth={90}>
                    {currentDocument?.change === null
                      ? `Версия ${currentDocument?.version}`
                      : `Изменение ${currentDocument?.change}`}
                    .
                  </Box>
                  <Box>{currentDocument?.createdAt?.split(' ')[0]}</Box>
                </Stack>

                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  spacing={2.5}
                  divider={<Divider orientation='vertical' style={{ height: 20, alignSelf: 'center' }} flexItem />}
                >
                  <PageNavigationWrapper direction='row' alignItems='center' spacing={0.5}>
                    <Box>
                      <CurrentPageInput />
                    </Box>
                    <Box>
                      / <NumberOfPages />
                    </Box>
                  </PageNavigationWrapper>

                  <ZoomWrapper direction='row' alignItems='center'>
                    <Box>
                      <ZoomOut>
                        {(props) => (
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              border: 'none',
                              borderRadius: '4px',
                              color: theme.palette.text.dark,
                              cursor: 'pointer',
                              padding: '8px',
                            }}
                            onClick={props.onClick}
                          >
                            -
                          </button>
                        )}
                      </ZoomOut>
                    </Box>
                    <Box>
                      <Zoom />
                    </Box>
                    <Box>
                      <ZoomIn>
                        {(props) => (
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              border: 'none',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              padding: '8px',
                            }}
                            onClick={props.onClick}
                          >
                            +
                          </button>
                        )}
                      </ZoomIn>
                    </Box>
                  </ZoomWrapper>

                  <Stack direction='row' alignItems='center' spacing={2}>
                    <Box>
                      <EnterFullScreen>
                        {(props) => (
                          <Tooltip title='На весь экран'>
                            <MenuIconButton onClick={props.onClick} disableRipple>
                              <FullScreenIcon />
                            </MenuIconButton>
                          </Tooltip>
                        )}
                      </EnterFullScreen>
                    </Box>
                    <Box>
                      <Rotate direction={RotateDirection.Backward}>
                        {(props) => (
                          <Tooltip title='Повернуть против часовой стрелки'>
                            <MenuIconButton onClick={props.onClick} disableRipple>
                              <Rotate90DegreesCcwIcon />
                            </MenuIconButton>
                          </Tooltip>
                        )}
                      </Rotate>
                    </Box>
                  </Stack>
                </Stack>

                <Stack direction='row' justifyContent='end' spacing={2}>
                  <Box>
                    <Download>
                      {(props) => (
                        <Tooltip title='Скачать'>
                          <MenuIconButton onClick={props.onClick} disableRipple>
                            <FileDownloadIcon />
                          </MenuIconButton>
                        </Tooltip>
                      )}
                    </Download>
                  </Box>
                  <Box>
                    <Print>
                      {(props) => (
                        <Tooltip title='Печать'>
                          <MenuIconButton onClick={props.onClick} disableRipple>
                            <PrintIcon />
                          </MenuIconButton>
                        </Tooltip>
                      )}
                    </Print>
                  </Box>
                  {currentDocument?.change ? (
                    role === 'admin' || role === 'client' || isExtraAccessArchitector ? (
                      <Box>
                        <Tooltip title='Удалить'>
                          <MenuIconButton onClick={() => onDeleteChangeClick(currentDocument)} disableRipple>
                            <DeleteIcon />
                          </MenuIconButton>
                        </Tooltip>
                      </Box>
                    ) : null
                  ) : role === 'admin' ? (
                    <Box>
                      <Tooltip title='Удалить'>
                        <MenuIconButton onClick={() => onDeleteVersionClick(currentDocument)} disableRipple>
                          <DeleteIcon />
                        </MenuIconButton>
                      </Tooltip>
                    </Box>
                  ) : null}
                  <Box>
                    <Tooltip title='Многостраничный просмотр'>
                      <MenuIconButton
                        onClick={onSwitchModeClick}
                        style={{
                          padding: 2,
                          backgroundColor:
                            switchMode === 'Vertical' ? theme.palette.bg.white : theme.palette.secondary.gray,
                          borderRadius: 6,
                        }}
                        disableRipple
                      >
                        <GridViewIcon
                          style={{
                            color: switchMode === 'Vertical' ? theme.palette.text.dark : theme.palette.text.light,
                          }}
                        />
                      </MenuIconButton>
                    </Tooltip>
                  </Box>
                </Stack>
              </Stack>
            )
          }}
        </Toolbar>
      )
    },
    [tomVersions, switchMode, currentDocument],
  )

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => {
      return [
        {
          ...defaultTabs[0],
          icon: (
            <Tooltip title='Миниатюра' placement='right'>
              <GridViewIcon style={{ marginTop: 4 }} />
            </Tooltip>
          ),
        },
      ]
    },
    renderToolbar,
    toolbarPlugin: {
      searchPlugin: { enableShortcuts: false },
    },
  })

  const {
    toolbarPluginInstance: {
      scrollModePluginInstance: { switchScrollMode },
    },
  } = defaultLayoutPluginInstance

  const handleRemarkButtonClick = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'remarks' }))
    dispatch(
      setRemarkFormData({
        remarkFormData: {
          page: docPageInfo.currentPage,
          pages: docPageInfo.pages,
          version: currentDocument.version,
          change: currentDocument.change,
        } as RemarkFormData,
      }),
    )
  }

  const handlePageChange = (e: PageChangeEvent) => {
    setDocPageInfo({ currentPage: e.currentPage + 1, pages: e.doc.numPages })
    dispatch(setDocumentPages({ documentPages: e.doc.numPages }))
  }

  return (
    <>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js'>
        <StyledPdfViewer>
          {!isAnnulled && isLatestVersion && remarkAcces && (
            <RemarkButton color='error' onClick={handleRemarkButtonClick}>
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                <path d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' fill='white' />
              </svg>
              Замечание
            </RemarkButton>
          )}
          <Viewer
            fileUrl={currentDocument?.link}
            plugins={[defaultLayoutPluginInstance]}
            onPageChange={handlePageChange}
            localization={ru_RU as unknown as LocalizationMap}
            renderLoader={() => <Progress />}
          />
        </StyledPdfViewer>
      </Worker>
      <ConfirmDialog />
    </>
  )
}

export default PdfViewer
