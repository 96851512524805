import { formatDateToString } from '@utils/formatDate'

import { setCurrentDocument } from '../../../store/slices/documentsPages/tom'
import { api } from '../../api'
import { GetTomRdByIdRequest, GetTomsRdRequest, tomRdApi } from '../tom'
import {
  DeleteTomRdChangeRequest,
  DeleteTomRdChangeResponse,
  DownloadTomRdChangeRequest,
  DownloadTomRdChangeResponse,
  GetTomRdChangesRequest,
  GetTomRdChangesResponse,
  ISendToProdChangeRdRequest,
  ISendToProdChangeRdResponse,
  TomRdChangeBaseResponse,
  UpdateTomRdChangeRequest,
  UploadTomRdChangeRequest,
} from './tomChangeApi.types'

export const tomRdChangeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomRdChanges: build.query<GetTomRdChangesResponse, GetTomRdChangesRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom/${id}/pdf/change/v2/list`,
        params,
        method: 'GET',
      }),
      providesTags: ['TomRd', 'TomRdDoc'],
    }),
    uploadTomRdChange: build.mutation<TomRdChangeBaseResponse, UploadTomRdChangeRequest>({
      query: ({ id, file, change }) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('change', JSON.stringify(change))

        return {
          url: `/project/tom/${id}/pdf/change/v2/upload`,
          method: 'POST',
          body: formData,
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedChange } = await queryFulfilled
          const { doc } = uploadedChange
          // getTomsRd
          const getTomsRdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsRd'))
          const getTomsRdLastKey = getTomsRdKeys[getTomsRdKeys?.length - 1]

          dispatch(
            tomRdApi.util.updateQueryData(
              'getTomsRd',
              state.api.queries[getTomsRdLastKey]?.originalArgs as GetTomsRdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === doc.tomId)
                const currentChangeMax = draft.data[changedTomIndex].changeMax

                if (doc.change > currentChangeMax) {
                  draft.data[changedTomIndex].changeMax = doc.change
                }
              },
            ),
          )

          // getTomRdById
          const getTomRdByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomRdById'))
          const getTomRdByIdLastKey = getTomRdByIdKeys[getTomRdByIdKeys?.length - 1]

          dispatch(
            tomRdApi.util.updateQueryData(
              'getTomRdById',
              state.api.queries[getTomRdByIdLastKey]?.originalArgs as GetTomRdByIdRequest,
              (draft) => {
                if (doc.change > draft.data.changeMax) {
                  draft.data.changeMax = doc.change
                }
              },
            ),
          )

          // getTomRdChanges
          const getTomRdChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomRdChanges'))
          const getTomRdChangesLastKey = getTomRdChangesKeys[getTomRdChangesKeys?.length - 1]

          dispatch(
            tomRdChangeApi.util.updateQueryData(
              'getTomRdChanges',
              state.api.queries[getTomRdChangesLastKey]?.originalArgs as GetTomRdChangesRequest,
              (draft) => {
                const indexForPlace = draft.data.findIndex((change) => doc.change > change.doc.change)
                draft.data.splice(indexForPlace, 0, uploadedChange)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: doc }))
        } catch {}
      },
    }),
    updateTomRdChange: build.mutation<TomRdChangeBaseResponse, UpdateTomRdChangeRequest>({
      query: ({ id, changeNum, change }) => ({
        url: `/project/tom/${id}/pdf/change/v2/update`,
        method: 'POST',
        params: { change: changeNum },
        body: change,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: updatedChange } = await queryFulfilled
          const { doc } = updatedChange
          // getTomsRd
          const getTomsRdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsRd'))
          const getTomsRdLastKey = getTomsRdKeys[getTomsRdKeys?.length - 1]

          dispatch(
            tomRdApi.util.updateQueryData(
              'getTomsRd',
              state.api.queries[getTomsRdLastKey]?.originalArgs as GetTomsRdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === doc.tomId)
                const currentChangeMax = draft.data[changedTomIndex].changeMax

                if (doc.change > currentChangeMax) {
                  draft.data[changedTomIndex].changeMax = doc.change
                }
              },
            ),
          )

          // getTomRdById
          const getTomRdByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomRdById'))
          const getTomRdByIdLastKey = getTomRdByIdKeys[getTomRdByIdKeys?.length - 1]

          dispatch(
            tomRdApi.util.updateQueryData(
              'getTomRdById',
              state.api.queries[getTomRdByIdLastKey]?.originalArgs as GetTomRdByIdRequest,
              (draft) => {
                if (doc.change > draft.data.changeMax) {
                  draft.data.changeMax = doc.change
                }
              },
            ),
          )

          // getTomRdChanges
          const getTomRdChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomRdChanges'))
          const getTomRdChangesLastKey = getTomRdChangesKeys[getTomRdChangesKeys?.length - 1]

          dispatch(
            tomRdChangeApi.util.updateQueryData(
              'getTomRdChanges',
              state.api.queries[getTomRdChangesLastKey]?.originalArgs as GetTomRdChangesRequest,
              (draft) => {
                const currentChangeIndex = draft.data.findIndex((change) => doc.docId === change.doc.docId)
                draft.data.splice(currentChangeIndex, 1)

                const indexForPlace = draft.data.findIndex((change) => doc.change > change.doc.change)
                draft.data.splice(indexForPlace, 0, updatedChange)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: doc }))
        } catch {}
      },
    }),
    deleteTomRdChange: build.mutation<DeleteTomRdChangeResponse, DeleteTomRdChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/tom/${id}/pdf/change/delete`,
        params: { change },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomRd', 'TomRdDoc'],
    }),
    downloadTomRdChange: build.mutation<DownloadTomRdChangeResponse, DownloadTomRdChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/tom/${id}/pdf/change/download`,
        params: { change },
        method: 'GET',
      }),
    }),
    // getTomRdChangeLink: build.mutation<GetTomRdChangeLinkResponse, GetTomRdChangeLinkRequest>({
    //   query: ({ id, change }) => ({
    //     url: `/project/tom/${id}/pdf/change/get-link`,
    //     params: { change },
    //     method: 'GET',
    //   }),
    // }),
    sendToProdChangeRd: build.mutation<ISendToProdChangeRdResponse, ISendToProdChangeRdRequest>({
      query: ({ id, change }) => ({
        url: `/project/tom/${id}/pdf/change/v2/to-prod`,
        method: 'POST',
        params: { change },
      }),
      async onQueryStarted({ id, change, shouldUpdateTable, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()

          // getTomRdChanges
          const getTomRdChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomRdChanges'))
          const getTomRdChangesLastKey = getTomRdChangesKeys[getTomRdChangesKeys?.length - 1]

          dispatch(
            tomRdChangeApi.util.updateQueryData(
              'getTomRdChanges',
              state.api.queries[getTomRdChangesLastKey]?.originalArgs as GetTomRdChangesRequest,
              (draft) => {
                const updatedChangeIndex = draft.data.findIndex((item) => item.doc.change === change)
                draft.data[updatedChangeIndex].change.inProd = true
                draft.data[updatedChangeIndex].change.prodDate = formatDateToString(new Date(), 'dd.MM.yyyy')
              },
            ),
          )

          shouldUpdateTable && dispatch(tomRdApi.util.invalidateTags([{ type: 'TomRd', id: 'NOTIFICATIONS' }]))
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomRdChangesQuery,
  useUploadTomRdChangeMutation,
  useUpdateTomRdChangeMutation,
  useDeleteTomRdChangeMutation,
  useDownloadTomRdChangeMutation,
  useSendToProdChangeRdMutation,
} = tomRdChangeApi
