import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import DownloadingIcon from '@mui/icons-material/Downloading'
import { Stack, Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useState } from 'react'

import { useDeleteAttachmentMutation, useDownloadAttachmentMutation } from '../../../../api/attachments'
import EmptyPage from '../../../../components/EmptyPage'
import { EmptyPageData } from '../../../../components/EmptyPage/EmptyPage.types'
import Progress from '../../../../components/Progress'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo'
import { currentDocumentSelector, tomSelector } from '../../../../store/slices/documentsPages/tom'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { StyledDataGrid, StyledGridActionsCellItem } from '../../../../styles/global/StyledDataGrid'
import { UserRolesEn, userRolesEnToRu } from '../../../../types/user'
import { downloadBlob } from '../../../../utils/dowloadBlob'
import { formatBytes } from '../../../../utils/formatBytes'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'
import { TomColoredInfo } from '../../../Docs/components/TomCard/TomCard.styles'
import { getEmptyPageData } from '../../../Home'
import { AttachmentsDrawer } from '../AttachmentsDrawer'
import { AttachmentsPageLegend } from '../AttachmentsPageLegend'
import { FileDataForDownload } from './AttachmentsPage.types'
import { useGetAttachments } from './hooks/useGetAttachments'

export const AttachmentsPage: FC = () => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled = tom?.features.annulment.annulled

  const { currentDocument } = useTypedSelector(currentDocumentSelector)

  const parsedDate = currentDocument.createdAt ? convertDateTime(currentDocument.createdAt, true) : '-'
  const [fileDataForDownload, setFileDataForDownload] = useState<FileDataForDownload | null>(null)
  const [downloadAttachment, downloadAttachmentResponse] = useDownloadAttachmentMutation()
  const [deleteAttachment, deleteAttachmentResponse] = useDeleteAttachmentMutation()

  const onAttachmentDownload = (attachmentId: number, name: string) => {
    downloadAttachment({ attachmentId })
    setFileDataForDownload({ name })
  }

  useMutationHandlers(downloadAttachmentResponse, (data: Blob) => {
    downloadBlob(data, fileDataForDownload?.name)
  })

  const attachmentDelete = (attachmentId: number) => {
    deleteAttachment({ attachmentId })
  }

  const handleConfirm = useCallback((confirm: boolean, attachmentId: number) => {
    if (confirm) {
      attachmentDelete(attachmentId)
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
    title: 'Удалить вложение?',
    body: currentDocument?.change
      ? `Файл к изменению ${currentDocument?.change} от ${parsedDate} будет удалён`
      : `Файл к версии ${currentDocument?.version} от ${parsedDate} будет удалён`,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onAttachmentDelete = useCallback((attachmentId: number) => {
    openConfirm(attachmentId)
  }, [])

  useMutationHandlers(deleteAttachmentResponse, () => {
    enqueueSnackbar('Вложение удалено', { variant: 'success' })
  })

  const columns: GridColumns = [
    {
      field: 'type',
      headerName: '',
      width: 63,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const value: string = params.value
        return (
          <TomColoredInfo direction='row' alignItems='center' customColor='blue' smallPadding thin>
            <Typography variant='tooltip' style={{ marginTop: 2 }}>
              {value}
            </Typography>
          </TomColoredInfo>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Название файла',
      minWidth: 260,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'removeLeftBorderHeader mediumXPaddingHeader',
      cellClassName: 'removeLeftBorderCell mediumXPaddingCell',
      sortable: false,
    },
    {
      field: 'size',
      headerName: 'Размер файла',
      minWidth: 260,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const value: string = params.value
        return (
          <div className='MuiDataGrid-cellContent' style={{ display: 'flex' }}>
            {formatBytes(Number(value))}
          </div>
        )
      },
    },
    {
      field: 'uploadedBy',
      headerName: 'Загрузил',
      minWidth: 260,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'employeeRole',
      headerName: 'Роль',
      minWidth: 260,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const value: UserRolesEn = params.value
        return (
          <div className='MuiDataGrid-cellContent' style={{ display: 'flex' }}>
            {userRolesEnToRu[value]}
          </div>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: 'Дата и время',
      minWidth: 260,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const value: string = params.value
        const localeDate = convertDateTime(value, true, true)
        return (
          <div className='MuiDataGrid-cellContent' style={{ display: 'flex' }}>
            {localeDate}
          </div>
        )
      },
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <StyledGridActionsCellItem
          icon={<DownloadIcon color='secondary' />}
          label='Скачать'
          onClick={() => onAttachmentDownload(params.row.id, params.row.name)}
          disableTouchRipple
        />,
        <StyledGridActionsCellItem
          icon={<DeleteIcon color='secondary' />}
          label='Удалить'
          onClick={() => onAttachmentDelete(params.row.id)}
          disableTouchRipple
        />,
      ],
      width: 120,
      cellClassName: 'removeLeftBorderCell',
    },
  ]

  const { attachments, isAttachmentsLoading } = useGetAttachments()

  const onUploadAttachmentsClick = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'attachments' }))
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    currentDocument.docId && !isAnnulled ? (
      <>
        К документу не загружено файлов, <br />
        для добавления нажмите кнопку Загрузить.
      </>
    ) : (
      <>К документу не загружено файлов.</>
    ),
    currentDocument.docId && !isAnnulled
      ? [
          {
            text: 'Загрузить',
            icon: DownloadingIcon,
            onClick: onUploadAttachmentsClick,
          },
        ]
      : [],
  )

  const legendTitle = currentDocument?.change
    ? `Файлы к изменению ${currentDocument?.change}, ${parsedDate}`
    : `Файлы к версии ${currentDocument?.version}, ${parsedDate}`

  return (
    <Stack flex={1} pr={1.65}>
      {isAttachmentsLoading ? (
        <Progress />
      ) : attachments?.length ? (
        <>
          <AttachmentsPageLegend title={legendTitle} onUploadAttachmentsClick={onUploadAttachmentsClick} />
          <StyledDataGrid
            rows={attachments}
            columns={columns}
            hideFooter
            headerHeight={40}
            rowHeight={40}
            showCellRightBorder
            showColumnRightBorder
            disableSelectionOnClick
            disableColumnMenu
            getRowClassName={() => 'disableHover'}
          />
        </>
      ) : (
        <EmptyPage data={emptyPageData} />
      )}

      <AttachmentsDrawer />
      <ConfirmDialog />
    </Stack>
  )
}
