import { api } from '../../api'
import {
  DeleteTomIrdVersionRequest,
  DownloadTomIrdVersionRequest,
  DownloadTomIrdVersionResponse,
  GetTomIrdVersionsRequest,
  GetTomIrdVersionsResponse,
  TomIrdVersionBaseResponse,
  UploadTomIrdVersionRequest
} from './tomVersionApi.types'
import { GetTomIrdByIdRequest, GetTomsIrdRequest, tomIrdApi } from '../tom'
import { setCurrentDocument } from '../../../store/slices/documentsPages/tom'

export const tomIrdVersionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomIrdVersions: build.query<GetTomIrdVersionsResponse, GetTomIrdVersionsRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom-ird/${id}/pdf/list`,
        params,
        method: 'GET',
      }),
      providesTags: ['TomIrd', 'TomIrdDoc'],
    }),
    createTomIrdVersion: build.mutation<TomIrdVersionBaseResponse, UploadTomIrdVersionRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/tom-ird/${id}/pdf/create`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedVersionData } = await queryFulfilled
          const uploadedVersion = uploadedVersionData.data

          // getTomsIrd
          const getTomsIrdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomsIrd'))
          const getTomsIrdLastKey = getTomsIrdKeys[getTomsIrdKeys?.length - 1]

          dispatch(
            tomIrdApi.util.updateQueryData(
              'getTomsIrd',
              state.api.queries[getTomsIrdLastKey]?.originalArgs as GetTomsIrdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex(tom => tom.id === uploadedVersion.tomId)
                draft.data[changedTomIndex].version = uploadedVersion.version
              }
            )
          )

          // getTomIrdById
          const getTomIrdByIdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomIrdById'))
          const getTomIrdByIdLastKey = getTomIrdByIdKeys[getTomIrdByIdKeys?.length - 1]

          dispatch(
            tomIrdApi.util.updateQueryData(
              'getTomIrdById',
              state.api.queries[getTomIrdByIdLastKey]?.originalArgs as GetTomIrdByIdRequest,
              (draft) => {
                draft.data.version = uploadedVersion.version
              }
            )
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedVersion }))

        } catch { }
      },
    }),
    uploadTomIrdVersion: build.mutation<TomIrdVersionBaseResponse, UploadTomIrdVersionRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/tom-ird/${id}/pdf/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedVersionData } = await queryFulfilled
          const uploadedVersion = uploadedVersionData.data

          // getTomsIrd
          const getTomsIrdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomsIrd'))
          const getTomsIrdLastKey = getTomsIrdKeys[getTomsIrdKeys?.length - 1]

          dispatch(
            tomIrdApi.util.updateQueryData(
              'getTomsIrd',
              state.api.queries[getTomsIrdLastKey]?.originalArgs as GetTomsIrdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex(tom => tom.id === uploadedVersion.tomId)
                draft.data[changedTomIndex].version = uploadedVersion.version
              }
            )
          )

          // getTomIrdById
          const getTomIrdByIdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomIrdById'))
          const getTomIrdByIdLastKey = getTomIrdByIdKeys[getTomIrdByIdKeys?.length - 1]

          dispatch(
            tomIrdApi.util.updateQueryData(
              'getTomIrdById',
              state.api.queries[getTomIrdByIdLastKey]?.originalArgs as GetTomIrdByIdRequest,
              (draft) => {
                draft.data.version = uploadedVersion.version
              }
            )
          )

          // getTomIrdVersions
          const getTomIrdVersionsKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomIrdVersions'))
          const getTomIrdVersionsLastKey = getTomIrdVersionsKeys[getTomIrdVersionsKeys?.length - 1]

          dispatch(
            tomIrdVersionApi.util.updateQueryData(
              'getTomIrdVersions',
              state.api.queries[getTomIrdVersionsLastKey]?.originalArgs as GetTomIrdVersionsRequest,
              (draft) => {
                draft.data.unshift(uploadedVersion)
              }
            )
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedVersion }))

        } catch {
        }
      },
    }),
    deleteTomIrdVersion: build.mutation<TomIrdVersionBaseResponse, DeleteTomIrdVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/tom-ird/${id}/pdf/delete`,
        params: { version },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomIrd', 'TomIrdDoc'],
    }),
    downloadTomIrdVersion: build.mutation<DownloadTomIrdVersionResponse, DownloadTomIrdVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/tom-ird/${id}/pdf/download`,
        params: { version },
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomIrdVersionsQuery,
  useCreateTomIrdVersionMutation,
  useUploadTomIrdVersionMutation,
  useDeleteTomIrdVersionMutation,
  useDownloadTomIrdVersionMutation,
} = tomIrdVersionApi
