import { api } from '../../api'
import {
  DeleteTomPdChangeRequest,
  DeleteTomPdChangeResponse,
  DownloadTomPdChangeRequest,
  DownloadTomPdChangeResponse,
  GetTomPdChangesRequest,
  GetTomPdChangesResponse,
  TomPdChangeBaseResponse,
  UpdateTomPdChangeRequest,
  UploadTomPdChangeRequest,
} from './tomChangeApi.types'
import { GetTomPdByIdRequest, GetTomsPdRequest, tomPdApi } from '../tom'
import { setCurrentDocument } from '../../../store/slices/documentsPages/tom'

export const tomPdChangeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomPdChanges: build.query<GetTomPdChangesResponse, GetTomPdChangesRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom-pd/${id}/pdf/change/v2/list`,
        params,
        method: 'GET',
      }),
      providesTags: ['TomPd', 'TomPdDoc'],
    }),
    uploadTomPdChange: build.mutation<TomPdChangeBaseResponse, UploadTomPdChangeRequest>({
      query: ({ id, file, change }) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('change', JSON.stringify(change))

        return {
          url: `/project/tom-pd/${id}/pdf/change/v2/upload`,
          method: 'POST',
          body: formData,
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedChange } = await queryFulfilled
          const { doc } = uploadedChange

          // getTomsPd
          const getTomsPdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsPd'))
          const getTomsPdLastKey = getTomsPdKeys[getTomsPdKeys?.length - 1]

          dispatch(
            tomPdApi.util.updateQueryData(
              'getTomsPd',
              state.api.queries[getTomsPdLastKey]?.originalArgs as GetTomsPdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === doc.tomId)
                const currentChangeMax = draft.data[changedTomIndex].changeMax

                if (doc.change > currentChangeMax) {
                  draft.data[changedTomIndex].changeMax = doc.change
                }
              },
            ),
          )

          // getTomPdById
          const getTomPdByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomPdById'))
          const getTomPdByIdLastKey = getTomPdByIdKeys[getTomPdByIdKeys?.length - 1]

          dispatch(
            tomPdApi.util.updateQueryData(
              'getTomPdById',
              state.api.queries[getTomPdByIdLastKey]?.originalArgs as GetTomPdByIdRequest,
              (draft) => {
                if (doc.change > draft.data.changeMax) {
                  draft.data.changeMax = doc.change
                }
              },
            ),
          )

          // getTomPdChanges
          const getTomPdChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomPdChanges'))
          const getTomPdChangesLastKey = getTomPdChangesKeys[getTomPdChangesKeys?.length - 1]

          dispatch(
            tomPdChangeApi.util.updateQueryData(
              'getTomPdChanges',
              state.api.queries[getTomPdChangesLastKey]?.originalArgs as GetTomPdChangesRequest,
              (draft) => {
                const indexForPlace = draft.data.findIndex((change) => doc.change > change.doc.change)
                draft.data.splice(indexForPlace, 0, uploadedChange)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: doc }))
        } catch {}
      },
    }),
    updateTomPdChange: build.mutation<TomPdChangeBaseResponse, UpdateTomPdChangeRequest>({
      query: ({ id, changeNum, change }) => ({
        url: `/project/tom-pd/${id}/pdf/change/v2/update`,
        method: 'POST',
        params: { change: changeNum },
        body: change,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: updatedChange } = await queryFulfilled
          const { doc } = updatedChange

          // getTomsPd
          const getTomsPdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsPd'))
          const getTomsPdLastKey = getTomsPdKeys[getTomsPdKeys?.length - 1]

          dispatch(
            tomPdApi.util.updateQueryData(
              'getTomsPd',
              state.api.queries[getTomsPdLastKey]?.originalArgs as GetTomsPdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === doc.tomId)
                const currentChangeMax = draft.data[changedTomIndex].changeMax

                if (doc.change > currentChangeMax) {
                  draft.data[changedTomIndex].changeMax = doc.change
                }
              },
            ),
          )

          // getTomPdById
          const getTomPdByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomPdById'))
          const getTomPdByIdLastKey = getTomPdByIdKeys[getTomPdByIdKeys?.length - 1]

          dispatch(
            tomPdApi.util.updateQueryData(
              'getTomPdById',
              state.api.queries[getTomPdByIdLastKey]?.originalArgs as GetTomPdByIdRequest,
              (draft) => {
                if (doc.change > draft.data.changeMax) {
                  draft.data.changeMax = doc.change
                }
              },
            ),
          )

          // getTomPdChanges
          const getTomPdChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomPdChanges'))
          const getTomPdChangesLastKey = getTomPdChangesKeys[getTomPdChangesKeys?.length - 1]

          dispatch(
            tomPdChangeApi.util.updateQueryData(
              'getTomPdChanges',
              state.api.queries[getTomPdChangesLastKey]?.originalArgs as GetTomPdChangesRequest,
              (draft) => {
                const currentChangeIndex = draft.data.findIndex((change) => doc.docId === change.doc.docId)
                draft.data.splice(currentChangeIndex, 1)

                const indexForPlace = draft.data.findIndex((change) => doc.change > change.doc.change)
                draft.data.splice(indexForPlace, 0, updatedChange)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: doc }))
        } catch {}
      },
    }),
    deleteTomPdChange: build.mutation<DeleteTomPdChangeResponse, DeleteTomPdChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/tom-pd/${id}/pdf/change/delete`,
        params: { change },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomPd', 'TomPdDoc'],
    }),
    downloadTomPdChange: build.mutation<DownloadTomPdChangeResponse, DownloadTomPdChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/tom-pd/${id}/pdf/change/download`,
        params: { change },
        method: 'GET',
      }),
    }),
    // getTomPdChangeLink: build.mutation<GetTomPdChangeLinkResponse, GetTomPdChangeLinkRequest>({
    //   query: ({ id, change }) => ({
    //     url: `/project/tom-pd/${id}/pdf/change/get-link`,
    //     params: { change },
    //     method: 'GET',
    //   }),
    // }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomPdChangesQuery,
  useUploadTomPdChangeMutation,
  useUpdateTomPdChangeMutation,
  useDeleteTomPdChangeMutation,
  useDownloadTomPdChangeMutation,
} = tomPdChangeApi
