import { api } from '../../api'
import {
  DeleteTomIiVersionRequest,
  DownloadTomIiVersionRequest,
  DownloadTomIiVersionResponse,
  GetTomIiVersionsRequest,
  GetTomIiVersionsResponse,
  TomIiVersionBaseResponse,
  UploadTomIiVersionRequest
} from './tomVersionApi.types'
import { GetTomIiByIdRequest, GetTomsIiRequest, tomIiApi } from '../tom'
import { setCurrentDocument } from '../../../store/slices/documentsPages/tom'

export const tomIiVersionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomIiVersions: build.query<GetTomIiVersionsResponse, GetTomIiVersionsRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom-ii/${id}/pdf/list`,
        params,
        method: 'GET',
      }),
      providesTags: ['TomIi', 'TomIiDoc'],
    }),
    createTomIiVersion: build.mutation<TomIiVersionBaseResponse, UploadTomIiVersionRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/tom-ii/${id}/pdf/create`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedVersionData } = await queryFulfilled
          const uploadedVersion = uploadedVersionData.data

          // getTomsIi
          const getTomsIiKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomsIi'))
          const getTomsIiLastKey = getTomsIiKeys[getTomsIiKeys?.length - 1]

          dispatch(
            tomIiApi.util.updateQueryData(
              'getTomsIi',
              state.api.queries[getTomsIiLastKey]?.originalArgs as GetTomsIiRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex(tom => tom.id === uploadedVersion.tomId)
                draft.data[changedTomIndex].version = uploadedVersion.version
              }
            )
          )

          // getTomIiById
          const getTomIiByIdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomIiById'))
          const getTomIiByIdLastKey = getTomIiByIdKeys[getTomIiByIdKeys?.length - 1]

          dispatch(
            tomIiApi.util.updateQueryData(
              'getTomIiById',
              state.api.queries[getTomIiByIdLastKey]?.originalArgs as GetTomIiByIdRequest,
              (draft) => {
                draft.data.version = uploadedVersion.version
              }
            )
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedVersion }))

        } catch { }
      },
    }),
    uploadTomIiVersion: build.mutation<TomIiVersionBaseResponse, UploadTomIiVersionRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/tom-ii/${id}/pdf/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedVersionData } = await queryFulfilled
          const uploadedVersion = uploadedVersionData.data

          // getTomsIi
          const getTomsIiKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomsIi'))
          const getTomsIiLastKey = getTomsIiKeys[getTomsIiKeys?.length - 1]

          dispatch(
            tomIiApi.util.updateQueryData(
              'getTomsIi',
              state.api.queries[getTomsIiLastKey]?.originalArgs as GetTomsIiRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex(tom => tom.id === uploadedVersion.tomId)
                draft.data[changedTomIndex].version = uploadedVersion.version
              }
            )
          )

          // getTomIiById
          const getTomIiByIdKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomIiById'))
          const getTomIiByIdLastKey = getTomIiByIdKeys[getTomIiByIdKeys?.length - 1]

          dispatch(
            tomIiApi.util.updateQueryData(
              'getTomIiById',
              state.api.queries[getTomIiByIdLastKey]?.originalArgs as GetTomIiByIdRequest,
              (draft) => {
                draft.data.version = uploadedVersion.version
              }
            )
          )

          // getTomIiVersions
          const getTomIiVersionsKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomIiVersions'))
          const getTomIiVersionsLastKey = getTomIiVersionsKeys[getTomIiVersionsKeys?.length - 1]

          dispatch(
            tomIiVersionApi.util.updateQueryData(
              'getTomIiVersions',
              state.api.queries[getTomIiVersionsLastKey]?.originalArgs as GetTomIiVersionsRequest,
              (draft) => {
                draft.data.unshift(uploadedVersion)
              }
            )
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedVersion }))

        } catch {
        }
      },
    }),
    deleteTomIiVersion: build.mutation<TomIiVersionBaseResponse, DeleteTomIiVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/tom-ii/${id}/pdf/delete`,
        params: { version },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomIi', 'TomIiDoc'],
    }),
    downloadTomIiVersion: build.mutation<DownloadTomIiVersionResponse, DownloadTomIiVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/tom-ii/${id}/pdf/download`,
        params: { version },
        method: 'GET',
      }),
    }),
    // getTomIiVersionLink: build.mutation<string, GetTomIiVersionLinkRequest>({
    //   query: ({ id, version }) => ({
    //     url: `/project/tom-ii/${id}/pdf/get-link`,
    //     params: { version },
    //     method: 'GET',
    //   }),
    // }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomIiVersionsQuery,
  useCreateTomIiVersionMutation,
  useUploadTomIiVersionMutation,
  useDeleteTomIiVersionMutation,
  useDownloadTomIiVersionMutation,
} = tomIiVersionApi
