import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const FullScreenIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M21 19.1H3V5H21V19.1ZM21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3Z' />
      <path d='M8.10547 9.98877L15.8946 9.98877L12 5.09275L8.10547 9.98877Z' />
      <path d='M15.8945 14.0928L8.10541 14.0928L12 18.9888L15.8945 14.0928Z' />
    </SvgIcon>
  )
}

export default FullScreenIcon